





























































import { NpcInfo, Scheme } from "@/interface/FfxivDTO";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FfxivNineCardSchemeForm extends Vue {
  @Prop() private npcList: NpcInfo[] = [];
  @Prop() private callback!: Function;
  @Prop() private closeBtnEvent!: Function;
  @Prop() private id: number = -1;

  submitForm: Scheme = {
    id: -1,
    createdAt: new Date(),
    name: "",
    describe: "",
    characterId: 0,
    updatedAt: new Date(),
    code: "",
    author: "",
    priority: 0,
  };

  validate() {
    const _this = this;
    this.submitForm.id = this.id;
    _this
      .$axios({
        url: '/api/v1/ffxiv/scheme/save',
        method: "put",
        data: _this.submitForm,
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.callback();
          _this.closeBtnEvent();
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  @Watch("id")
  getSchemeById() {
    if (this.id == -1) {
      return;
    }
    const _this = this;
    this.submitForm.id = this.id;
    _this
      .$axios({
        url: '/api/v1/ffxiv/scheme/query/id',
        method: "put",
        data: {
          id: _this.id,
        },
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.submitForm = response.data.data;
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  deleteBtnEvent() {
    if (this.id == -1) {
      return;
    }
    const _this = this;
    this.submitForm.id = this.id;
    _this
      .$axios({
        url: '/api/v1/ffxiv/scheme/delete/id',
        method: "delete",
        data: {
          id: _this.id,
        },
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.callback();
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }
}
