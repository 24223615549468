














































































import { Card, SchemeNode } from "@/interface/FfxivDTO";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FfxivNineCardSchemeNodeForm extends Vue {
  @Prop() private callback!: Function;
  @Prop() private closeBtnEvent!: Function;
  @Prop() private nodeItem!: SchemeNode;
  @Prop() private schemeId!: number;
  @Prop() private cardItems!: Array<Card>;
  @Prop() private cardPositionItems!: Array<number>;

  selectCardItem: Card = {
    id: 0,
    type: 0,
    rarity: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    cardNo: "",
    cardCode: "",
    name: "",
    describe: "",
    title: "",
  };

  endNodeSwitch = false;

  residueCardItems = [];

  addChildrenNode() {
    this.nodeItem.parentId = this.nodeItem.id;
    this.nodeItem.id = -1;
    this.nodeItem.cardId = this.selectCardItem.id;
    if (this.endNodeSwitch) {
      this.nodeItem.cardId = -1;
      this.nodeItem.cardPosition = 0;
    }
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/scheme/node',
        method: "put",
        data: _this.nodeItem,
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.callback();
          _this.closeBtnEvent();
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  deleteCurrentNode() {
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/scheme/node',
        method: "delete",
        data: _this.nodeItem,
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.callback();
          _this.closeBtnEvent();
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  @Watch("nodeItem.cardPosition")
  @Watch("selectCardItem")
  selectCardPositionEvent() {
    this.nodeItem.name = `${this.selectCardItem.name} ${this.nodeItem.cardPosition}`;
  }
}
