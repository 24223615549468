import Vue from 'vue';
import Component from 'vue-class-component';
import FfxivNineCardSchemeForm from "@/components/ffxiv/SchemeForm.vue";
import { Card, NpcInfo, Scheme, SchemeNode, SchemeNodeTreeStruct } from '@/interface/FfxivDTO';
import CardInfoBox from '@/components/ffxiv/CardInfoBox.vue';
import { Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import FfxivNineCardSchemeNodeForm from "@/components/ffxiv/SchemeNodeForm.vue";
import FfxivCardData, { formatToTree } from '@/utils/ffxiv-text-tool';
import { authCheck } from '@/utils/ffxiv-text-tool';

@Component({
  components: {
    FfxivNineCardSchemeForm,
    CardInfoBox,
    FfxivNineCardSchemeNodeForm
  }
})
export default class FfxivNineCardSchemeEdit extends Vue {

  authCheck = authCheck;

  schemeSaveDialogShow = false;
  schemeNodeEditDialogShow = false;

  schemeNodeEditDialogLoading = false;
  schemeSaveDialogLoading = false;


  selectSchemeItem: Scheme = {
    id: -1,
    createdAt: new Date(),
    name: '',
    describe: '',
    characterId: 0,
    updatedAt: new Date(),
    code: '',
    author: '',
    priority: 0
  };

  selectNpcId = -1;
  npcList: NpcInfo[] = [];
  npcCardsList: Card[] = [];

  schemeList: Scheme[] = [];

  schemeNodeCardItems: Card[] = [];
  cardPositionItems: number[] = [];

  schemeNodeRelData: SchemeNode[] = [];



  selectSchemeNodeItem: SchemeNode = {
    id: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    name: '',
    cardId: 0,
    schemeId: 0,
    describe: '',
    first: 0,
    parentId: 0,
    cardPosition: 0
  }


  cardsData = [];
  schemeCards = [];

  form = {
    schemeCards: []
  }

  tabsViewForm = {
    selectItem: ''
  }

  schemeTreeViewData: SchemeNodeTreeStruct = {
    offensive: {
      id: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      name: '',
      cardId: 0,
      schemeId: 0,
      describe: '',
      first: 0,
      parentId: 0,
      cardPosition: 0
    },
    defensive: {
      id: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      name: '',
      cardId: 0,
      schemeId: 0,
      describe: '',
      first: 0,
      parentId: 0,
      cardPosition: 0
    }
  }


  mounted() {
    this.getAllNpcData();
    this.getAllCard();
  }

  getAllNpcData() {
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/npc',
        method: "get",
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.npcList = response.data.data;
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  schemeDialogSaveEventCallback() {
    this.schemeSaveDialogShow = false;
    this.selectNpcItemEvent();
  }

  schemeDialogCloseBtnEvent() {
    this.schemeSaveDialogShow = false;
  }


  @Watch("selectNpcId")
  selectNpcItemEvent() {
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/scheme/query/npc/id',
        method: "put",
        data: {
          id: _this.selectNpcId
        }
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.schemeList = response.data.data;
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });

    this.getNpcCardsData();
  }


  schemeEditBtnEvent(item: Scheme) {
    this.selectSchemeItem = item;
    this.schemeSaveDialogShow = true;
    this.schemeSaveDialogLoading = true;
    const _this = this;
    const timer = setTimeout(function () {
      _this.schemeSaveDialogLoading = false;
      clearTimeout(timer);
    }, 1000)
  }


  saveSchemeCards() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/scheme/cards/save',
      method: 'put',
      data: {
        id: _this.selectSchemeItem.id,
        cards: _this.form.schemeCards
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.selectSchemeEvent(_this.selectSchemeItem)
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  getAllCard() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/card',
      method: 'get'
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.cardsData = response.data.data;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }


  remove(item: Card) {
    const index = this.form.schemeCards.indexOf((item.id as never))
    if (index >= 0) this.form.schemeCards.splice(index, 1)
  }

  selectSchemeEvent(item: Scheme) {

    this.selectSchemeItem = item;
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/scheme/cards/query/id',
      method: 'put',
      data: {
        id: item.id
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.schemeCards = response.data.data;
        const tempList: never[] = [];
        response.data.data.forEach((value: Card) => {
          tempList.push((value.id as never));
        });
        _this.form.schemeCards = tempList;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });

    _this.$axios({
      url: '/api/v1/ffxiv/scheme/node/query/id',
      method: 'put',
      data: {
        id: item.id
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.schemeNodeRelData = response.data.data;
        _this.schemeTreeViewData.offensive = response.data.data.filter((item: SchemeNode) => item.first === 1)[0];
        _this.schemeTreeViewData.defensive = response.data.data.filter((item: SchemeNode) => item.first === 0)[0];

        _this.schemeNodeTreeDataHandler();

        _this.createTreeView();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });


  }


  schemeNodeTreeDataHandler() {
    const [...tempList] = this.schemeNodeRelData;

    const offensiveChildren = formatToTree(tempList as any, this.schemeTreeViewData.offensive.id);
    const defensiveChildren = formatToTree(tempList as any, this.schemeTreeViewData.defensive.id);

    this.schemeTreeViewData.offensive.children = offensiveChildren;
    this.schemeTreeViewData.defensive.children = defensiveChildren;

  }


  getNpcCardsData() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/npc/cards/query/id',
      method: 'put',
      data: {
        id: _this.selectNpcId
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.npcCardsList = response.data.data;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  createTreeView() {
    const _this = this;
    const chartDom: HTMLElement = document.getElementById('schemeTreeView')!;
    const myChart = echarts.init(chartDom, 'dark');
    myChart.showLoading();
    const option = {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove'
      },
      legend: {
        top: '2%',
        left: '3%',
        orient: 'vertical',
        data: [
          {
            name: '先手视图',
            icon: 'rectangle'
          },
          {
            name: '后手视图',
            icon: 'rectangle'
          }
        ],
        borderColor: '#c23531'
      },
      series: [
        {
          type: 'tree',
          roam: true,
          expandAndCollapse: false,
          name: '先手视图',
          data: [this.schemeTreeViewData.offensive],
          top: '5%',
          left: '7%',
          bottom: '2%',
          right: '60%',
          symbolSize: 7,
          label: {
            position: 'left',
            verticalAlign: 'middle',
            align: 'right'
          },
          leaves: {
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left'
            }
          },
          emphasis: {
            focus: 'descendant'
          },
          animationDuration: 550,
          animationDurationUpdate: 750
        },
        {
          type: 'tree',
          name: '后手视图',
          data: [this.schemeTreeViewData.defensive],
          roam: true,
          top: '20%',
          left: '60%',
          bottom: '22%',
          right: '18%',
          symbolSize: 7,
          label: {
            position: 'left',
            verticalAlign: 'middle',
            align: 'right'
          },
          leaves: {
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left'
            }
          },
          expandAndCollapse: false,
          emphasis: {
            focus: 'descendant'
          },
          animationDuration: 550,
          animationDurationUpdate: 750
        }
      ]
    };

    myChart.on('click', (parameter: echarts.ECElementEvent) => {
      if (!authCheck()) {
        return;
      }

      _this.selectSchemeNodeItem = parameter.data as SchemeNode;

      //需要计算出卡组
      let tempCards: Card[] = [];
      const srcTreeMapLineTextList = parameter.treeAncestors;

      const occupiedPosition: Array<number> = [];
      const occupiedCardText: Array<string> = [];

      srcTreeMapLineTextList.forEach((item: any) => {
        const result: Array<string> = item.name.match(/.* [0-9]/g);
        if (result != null && result.length > 0) {
          occupiedCardText.push(result[0].substring(0, result[0].length - 2));
          occupiedPosition.push(parseInt(result[0].substring(result[0].length - 1)));
        }
      });

      if (parameter.seriesIndex == 0) {
        if (parameter.treeAncestors.length % 2 == 0) {
          [...tempCards] = _this.schemeCards;
        } else {
          [...tempCards] = _this.npcCardsList;
        }
      }
      if (parameter.seriesIndex == 1) {
        if (parameter.treeAncestors.length % 2 == 0) {
          [...tempCards] = _this.npcCardsList;
        } else {
          [...tempCards] = _this.schemeCards;
        }
      }

      const resultSchemeCards = tempCards.filter((card: Card) => {
        const cardResult = occupiedCardText.filter((cardNameText: string) => {
          return cardNameText == card.name;
        });
        return cardResult.length == 0;
      });

      _this.schemeNodeCardItems = resultSchemeCards;
      _this.cardPositionItems = FfxivCardData.cardPositionItems.filter((itemA) => !occupiedPosition.some((itemB) => itemA === itemB));

      _this.schemeNodeEditDialogShow = true;
      _this.schemeNodeEditDialogLoading = true;
      const timer = setTimeout(function () {
        _this.schemeNodeEditDialogLoading = false;
        clearTimeout(timer);
      }, 2000);

    });

    myChart.hideLoading();
    myChart.setOption(option);
  }

  schemeNodeDialogCloseBtnEvent() {
    this.schemeNodeEditDialogShow = false;

  }

  schemeNodeSubmitCallbackEvent() {
    this.schemeNodeEditDialogShow = false;
    this.selectSchemeEvent(this.selectSchemeItem);
  }




}





