<template>
  <div class="ffxiv-nine-card-scheme-edit">
    <v-row>
      <v-col cols="12" md="4" sm="12" xl="4" lg="4">
        <v-card dark min-height="650">
          <v-card-title>
            案例列表
            <v-spacer></v-spacer>
            <v-btn @click="schemeSaveDialogShow = true">新增案例</v-btn>
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="selectNpcId"
              :items="npcList"
              item-text="name"
              item-value="id"
            ></v-select>
            <v-card class="overflow-y-auto grey darken-2 pa-1" max-height="500">
              <v-card
                v-for="item in schemeList"
                :key="item.id"
                class="ma-1"
                @click="selectSchemeEvent(item)"
              >
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title>{{ item.name }}</v-card-title>
                    <v-card-subtitle>{{ item.author }}</v-card-subtitle>
                    <v-card-text>
                      {{ item.describe }}
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        color="blue-grey darken-3"
                        @click="schemeEditBtnEvent(item)"
                        v-if="authCheck()"
                        >编辑</v-btn
                      >
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" sm="12" xl="8" lg="8">
        <v-card dark min-height="650">
          <v-card-text>
            <v-tabs v-model="tabsViewForm.selectItem" dark>
              <v-tab href="#tab-1"> 对局节点树形图 </v-tab>
              <v-tab href="#tab-2"> 对局卡组编辑 </v-tab>

              <v-tabs-items v-model="tabsViewForm.selectItem" dark>
                <v-tab-item value="tab-1">
                  <v-card :loading="schemeNodeEditDialogLoading">
                    <div class="scheme-tree-view" id="schemeTreeView"></div
                  ></v-card>
                </v-tab-item>

                <v-tab-item value="tab-2">
                  <v-card>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12">
                          <v-card
                            color="blue-grey darken-1"
                            dark
                            v-if="selectSchemeItem.id != -1"
                          >
                            <template v-slot:progress>
                              <v-progress-linear
                                absolute
                                color="green lighten-3"
                                height="4"
                                indeterminate
                              ></v-progress-linear>
                            </template>
                            <v-form v-show="authCheck()">
                              <v-container>
                                <v-row dense>
                                  <v-col cols="12">
                                    <v-autocomplete
                                      v-model="form.schemeCards"
                                      :items="cardsData"
                                      filled
                                      chips
                                      color="blue-grey lighten-2"
                                      label="Select"
                                      item-text="name"
                                      item-value="id"
                                      multiple
                                    >
                                      <template v-slot:selection="data">
                                        <v-chip
                                          v-bind="data.attrs"
                                          :input-value="data.selected"
                                          close
                                          @click="data.select"
                                          @click:close="remove(data.item)"
                                        >
                                          <v-avatar left>
                                            <v-img
                                              :src="
                                                '/resources/ffxiv/surface_icon/' +
                                                data.item.cardCode +
                                                '.png'
                                              "
                                            ></v-img>
                                          </v-avatar>
                                          {{ data.item.name }}
                                        </v-chip>
                                      </template>
                                      <template v-slot:item="data">
                                        <template>
                                          <v-list-item-avatar>
                                            <img
                                              :src="
                                                '/resources/ffxiv/surface_icon/' +
                                                data.item.cardCode +
                                                '.png'
                                              "
                                            />
                                          </v-list-item-avatar>
                                          <v-list-item-content>
                                            <v-list-item-title>{{
                                              data.item.name
                                            }}</v-list-item-title>
                                          </v-list-item-content>
                                        </template>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                  <v-btn @click="saveSchemeCards"
                             
                                    >保存卡牌</v-btn
                                  >
                                </v-row>
                              </v-container>
                            </v-form>
                          </v-card>
                        </v-col>
                        <v-col cols="12">
                          <CardInfoBox
                            v-for="item in schemeCards"
                            :key="item.id"
                            :cardBoxData="item"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="schemeSaveDialogShow" max-width="700">
      <v-card dark :loading="schemeSaveDialogLoading">
        <v-card-title> 对局案例 </v-card-title>
        <v-card-text>
          <FfxivNineCardSchemeForm
            :id="selectSchemeItem.id"
            :callback="schemeDialogSaveEventCallback"
            :npcList="npcList"
            :closeBtnEvent="schemeDialogCloseBtnEvent"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="schemeNodeEditDialogShow" max-width="700">
      <v-card dark :loading="schemeNodeEditDialogLoading">
        <v-card-title>节点编辑</v-card-title>

        <v-card-text>
          <FfxivNineCardSchemeNodeForm
            :cardItems="schemeNodeCardItems"
            :nodeItem="selectSchemeNodeItem"
            :schemeId="selectSchemeItem.id"
            :closeBtnEvent="schemeNodeDialogCloseBtnEvent"
            :callback="schemeNodeSubmitCallbackEvent"
            :cardPositionItems="cardPositionItems"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<style scoped>
.scheme-tree-view {
  width: 100%;
  min-height: 580px;
}
</style>


<script>
import FfxivNineCardSchemeEdit from "./schemeEdit";
export default FfxivNineCardSchemeEdit;
</script>
